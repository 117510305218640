.footer {
    width: 100vw;
    max-width: 1440px;
    height: fit-content;
    border-radius: 0px;
    background-color: var(--primary-gray-dark);
    padding: 0px 120px;
    color: var(--default-white) !important;
    margin-top: 80px;
}

@media only screen and (max-width: 1024px) {
  .footer {
    padding: 32px 24px;
  }
}